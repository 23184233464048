import React from 'react';
import { Layouts } from '../components/components';
import { SearchResult } from '../modules/modules';

const SearchPage: React.FC = () => {
  const { IndexLayout } = Layouts;
  return (
    <IndexLayout>
      <SearchResult />
    </IndexLayout>
  );
};

export default SearchPage;
